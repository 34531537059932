import React from 'react';
import './NotFound.css'; // Create a CSS file for styling

const NotFound: React.FC = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404</h1>
        <p>Oops! The page you're looking for doesn't exist.</p>
      </div>
    </div>
  );
};

export default NotFound;

// App.tsx

import React from 'react';
import BillCardView from './billCardView';
import { BillItem ,ICustomerBills } from './interface';
import logo from '../src/logos/swiggy.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './App.css'
import { BsFillStarFill ,BsGeoAltFill,BsFillPlusCircleFill } from 'react-icons/bs';
import axios from 'axios';
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import NotFound from './NotFound';


const billItems: BillItem[] = [
  {
    bill_id: 1,
    bill_number: 'BILL001',
    item_name: 'Item 1',
    tax_amount: 10.0,
  },
  {
    bill_id: 2,
    bill_number: 'BILL002',
    item_name: 'Item 2',
    tax_amount: 15.0,
  },
  // Add more bill items as needed
];

const Home: React.FC = () => {
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString)
const fragment = window.location.hash.substr(1); 
    
const id = urlParams.get('');
//const chars = id?id.split('+'):[]
//const subdomains = urlParams.get('merchant');

// Display the value in the console
   console.log('fragment',fragment)

   const combinedString = fragment;
   
   const decodedValue = decodeURIComponent(combinedString);

  // Split the decoded value using '+'
  const values = decodedValue.split('-');// Get the first element of the array

   //const individualValues =combinedString&& combinedString.split('+'); // Split the combined string using the space character as the separator

//console.log(individualValues); 

   console.log('char',values)
  // let { id }:any = useParams();
  // console.log('param value',id)

//   const {id}:any = useLocation();
//   console.log('uselocation',id)

 
  const [datas,setDatas]= React.useState({stores:{name:'',address1:'',address2:'',desc:''},bill_amounts:{total_invoice_value:'',pretax_value:'',discount:'',other_charges:'',tax_amount_1:'',tax_amount_2:''},bill_items:[],customers:{id:'',city:'',locality:''},createdAt:''});
  const[error,setError]=React.useState(false)
  const [date,setDate]=React.useState('')
  console.log('responsee data',datas)
  const billItemLenth =datas.bill_items? datas.bill_items.length:0
  console.log('billitem length')

   React.useEffect(()=>{

   let  uploadUrl =fragment? `${process.env.REACT_APP_API_URL}bills?subdomains=${values[0]}&bill_id=${values[1]}&openApi=${true}`:`${process.env.REACT_APP_API_URL}bills?subdomains=EjF12&bill_id=uV9kp&openApi=${true}`
   let currentToken =process.env.REACT_APP_CURRENT_TOKEN
 
   console.log('upload url',uploadUrl)
   console.log('currentToken',currentToken)

   axios.get(uploadUrl)
      .then(response => {
        setError(false)
        console.log('responseCustomerBills',response.data);
        if(!response.data?.bill_amounts ){
            console.log('null')
            setError(true)
        }
        const date = new Date(); 
        console.log('date',response.data.createdAt)
      
        const originalDate = new Date(response.data.createdAt);
        const formatDate =   originalDate.toUTCString();
        // const options = {
        //     year: "numeric",
        //     month: "long",
        //     day: "numeric",
        //     hour: "numeric",
        //     minute: "numeric",
        //     second: "numeric",
        //     //timeZoneName: "short"
        //   };
          
        //   const formattedDate = new Intl.DateTimeFormat("en-US", options).format)
          
          console.log('originalDate',formatDate);
          setDate(formatDate)
    
        setDatas(response.data)
        return response.data;
      })
      .catch(err => {
          setError(true)
          console.log('errorRes',err.response);          
          return err.response.data;
      });

  },[])

  const formatDate = (date:any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};
//console.log('formatDate',formatDate)


  return (
    !error?
    <div style={{display:"flex",justifyContent:'flex-start',alignItems:'center',flexDirection:'column',backgroundColor:'#E1EBF1',padding:'32px',height:billItemLenth >3?'auto':'100vh'}}>
      <div className='responsive-div'>
      <div style={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',gap:'9px'}}>
      <div>
     <img style={{ height: '50px', width: '50px', filter: 'grayscale(100%)' }} src={logo} alt="Logo" />
     </div>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%',alignItems:"center",marginTop:'3px'}}>
      <div style={{display:'flex',flexDirection:'column',gap:'9px',}}>
       <div style={{fontSize:'16px',fontFamily: 'Inter, sans-serif'}}>{datas?datas.stores.name:null}</div>
      <div style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#626262'}}>24 - ganapathy{datas?datas.stores.address1:null}</div>
     
      {/* <td>{data?data.stores.desc:null}</td> */}
      </div>

     

      </div>

     </div>
  <div>
  <hr className="dashed" ></hr>
  <div style={{display:'flex',flexDirection:'row',gap:'9px'}}>
   <div style={{display:'flex',flexDirection:'row',gap:'9px'}}>
    <div style={{fontFamily: 'Inter, sans-serif'}}>NAME :</div>
    <div style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#626262'}}>walk in</div>
    {/* <div style={{fontFamily: 'Inter, sans-serif'}}>NAME :</div>
    //<div style={{fontFamily: 'Inter, sans-serif'}}>DATE :</div> */}

      </div>
   {/* <div style={{display:'flex',flexDirection:'row',gap:'9px',}}> */}
   {/* <div style={{fontFamily: 'Inter, sans-serif'}}>#ID :</div>
   <div style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#626262'}}>{id?id:122995020}</div> */}
    {/* <div style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#626262'}}>walk in</div>
    <div style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#626262'}}>{date}</div> */}

      {/* </div> */}
      </div>
      <div style={{display:'flex',flexDirection:'row',gap:'9px',paddingTop:'5px'}}>
      <div style={{fontFamily: 'Inter, sans-serif'}}>DATE :</div>
      <div style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#626262'}}>{date}</div>
      </div>
  </div>


     <hr className="dashed" ></hr>
  
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
<th style={{fontSize:'14px',fontFamily: 'Inter, sans-serif',color:"black",fontWeight: 300}}>ITEM</th>
<th style={{fontSize:'14px',fontFamily: 'Inter, sans-serif',color:"black",fontWeight: 300}}>QTY</th>
<th style={{fontSize:'14px',fontFamily: 'Inter, sans-serif',color:"black",fontWeight: 300}}>PRICE</th>
<th style={{fontSize:'14px',fontFamily: 'Inter, sans-serif',color:"black",fontWeight: 300}}>AMT</th>
</div>
<hr className="dashed"></hr>

{datas.bill_items&&datas.bill_items.length >0?datas.bill_items.map((data:any,index)=>{
  //console.log('index',index)
return(
<>

  <div >
    <td style={{ fontFamily: 'Inter, sans-serif', fontWeight: 500, color:'black' ,textTransform: 'uppercase'}}>{data.item_name}</td>
 
</div>

<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'5px'}}>
 <td style={{fontFamily: 'Times New Roman", Times, serif',}}>{data.serial_number}</td>
 <td style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#333230'}}>{Math.floor(data.qty)}</td>
 <td style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#333230'}}>${data.unit_price}</td>
 <td style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#333230'}}>${data.total_amount}</td>
</div>
<div style={{paddingTop:'6px',fontSize:'10px'}}>
 <i>${data?data.pretax_value:null} + $138.15( CGST@${data?data.tax_amount_1:null}%,SGST@${data?data.tax_amount_2:null}% )</i> 
</div>
 {index +1 < billItemLenth?<hr className="solid"></hr>:null}

</>
)
}):null}

 {/* <div style={{display:'flex',flexDirection:'row',}}>
  <td>FRESHO -AMILA(LOOSE)</td>
</div>
<br></br>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
 <td>DWwlmertt</td>
 <td>10</td>
 <td>$9.14</td>
 <td>$123.44</td>
</div>
<div style={{paddingTop:'10px'}}>
 <i>$578.68+$138.15(CGST@8.0%,SGST@8.0%)</i> 
</div>
<br></br>
<div style={{display:'flex',flexDirection:'row',}}>
  <td>AJMAL -AURUM EDP FRUITY PERFUME FOR WOMEN</td>
</div>
<br></br>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
 <td>SQWDdddh</td>
 <td>20</td>
 <td>$55.14</td>
 <td>$543.44</td>
</div>
<div style={{paddingTop:'10px'}}>
 <i>$578.68+$138.15(CGST@8.0%,SGST@8.0%)</i> 
</div>
<br></br>

<div style={{display:'flex',flexDirection:'row',}}>
  <td>APPLE _ORANGE</td>
</div>
<br></br>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
 <i>JNJJJnnd</i>
 <i>30</i>
 <i>$91.14</i>
 <i>$344.44</i>
</div>
<div style={{paddingTop:'10px'}}>
 <i>$578.68+$138.15(CGST@8.0%,SGST@8.0%)</i> 
</div>
<br></br>

<div style={{display:'flex',flexDirection:'row',}}>
  <td>FRESHO -AMILA(LOOSE)</td>
</div>
<br></br>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
 <td>DWwlmertt</td>
 <td>10</td>
 <td>$9.14</td>
 <td>$123.44</td>
</div>
<div style={{paddingTop:'10px'}}>
 <i>$578.68+$138.15(CGST@8.0%,SGST@8.0%)</i> 
</div>
<br></br>
<div style={{display:'flex',flexDirection:'row',}}>
  <td>VEG_EGG</td>
</div>
<br></br>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
 <td>SSSedee</td>
 <td>60</td>
 <td>$98.14</td>
 <td>$663.44</td>
</div>
<div style={{paddingTop:'10px'}}>
 <i>$578.68+$138.15(CGST@8.0%,SGST@8.0%)</i> 
</div> */}
<br>
</br>
<hr className="dashed"></hr>
<br></br>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
<div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
<td style={{ fontFamily: 'Inter, sans-serif', fontWeight: 500, color:'black' }}>PRE TAX VALUE</td>
<td style={{ fontFamily: 'Inter, sans-serif', fontWeight: 500, color:'black' }}>CGST</td>
<td style={{ fontFamily: 'Inter, sans-serif', fontWeight: 500, color:'black' }}>SGST</td>
<td style={{ fontFamily: 'Inter, sans-serif', fontWeight: 500, color:'black' }}>DISCOUNT</td>
<td style={{ fontFamily: 'Inter, sans-serif', fontWeight: 500, color:'black' }}>OTHER Charges</td>
</div>
<div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
<td style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#333230'}}>${datas?datas.bill_amounts.pretax_value:null}</td>
<td style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#333230'}}>${datas?datas.bill_amounts.tax_amount_1:null}</td>
<td style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#333230'}}>${datas?datas.bill_amounts.tax_amount_2:null}</td>
<td style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#333230'}}>${datas?datas.bill_amounts.discount:null}</td>
<td style={{fontFamily: 'Inter, sans-serif', fontWeight: 300,color:'#333230'}}>${datas?datas.bill_amounts.other_charges:null}</td>
</div>
</div>
<br></br>
<hr className="dashed"></hr>
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
  <td style={{ fontFamily: 'Inter, sans-serif', fontWeight: 500, color:'black' }}>TOTAL INVOICE VALUE</td>
  <td style={{ fontWeight: 'bold',fontFamily: 'Inter, sans-serif'}}>${datas?datas.bill_amounts.total_invoice_value:null}</td>
</div>

      </div>
      <div style={{marginTop:"10px"}}>Copyright © 2023 Bills2u </div>
      </div>:<NotFound/>
    
  );
};

export default Home;
